<script setup>
import {onMounted, ref, watch} from 'vue';

const props = defineProps({
    modelValue: String,
    label: String,
    error: String,
    placeholder: String,
    validate: Object,
    validateRule: String,
    classes: Array,
    disabled: Boolean,
});

defineEmits(['update:modelValue']);

const input = ref(null);
const val_error = ref(null);

watch( () =>  props.validate ? props.validate.fire : null, (val) => {
    if (val)
        validate();
});

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

const validate = () => {

    if (props.validate) {

        if (props.validateRule === "empty") {
            if (input.value.value === '') {
                val_error.value = `El campo ${props.label} es obligatorio`;
                if (!props.validate.error) {
                    focus();
                }
                props.validate.error = props.validate.error || true;
            } else {
                val_error.value = '';
            }
        }

        if (props.validateRule === "email") {
            if (input.value.value !== '') {

                if (!String(input.value.value).toLowerCase().match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    val_error.value = `Escriba una dirección de correo válida`;
                    if (!props.validate.error)
                        focus();
                    props.validate.error = props.validate.error || true;
                } else {
                    val_error.value = '';
                }

            } else {
                val_error.value = '';
            }
        }


    }
}
const focus = () => input.value.focus();

defineExpose({ focus });

</script>

<template>
    <div :class="{'flex flex-row': props.inline}">
        <label class="block font-medium text-sm text-gray-700 mb-1"
           :class="$props.classes ? $props.classes[0] : ''">
            <span>{{ label }}</span>
        </label>
        <input
            ref="input"
            class="text-sm border-gray-300 text-gray-700 focus:border-red-800 focus:ring-red-800 rounded-md shadow-sm h-10 w-full font-sans placeholder:text-gray-400 "
            :class="$props.classes ? $props.classes[1] : ''"
            :value="modelValue"
            :placeholder="placeholder"
            :disabled="$props.disabled ? 'disabled' : null"
            @input="$emit('update:modelValue', $event.target.value); validate();">
        <div v-show="error || val_error" class="mt-1">
            <p class="text-sm text-red-600">
                {{ error }} {{ val_error }}
            </p>
        </div>
    </div>
</template>
